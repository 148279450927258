/* Position and style the chat toggle button */
.chat-icon {
    position: fixed;
    right: 10px; /* Adjust for a bit of margin from the edge */
    bottom: 10px; /* Same here for bottom margin */
    background-color: #007bff; /* Example color - blue */
    color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-radius: 50%; /* Make it perfectly circular */
    z-index: 1000; /* Ensure it's above most other elements */
    padding: 15px; /* Add some padding for the icon */
    cursor: pointer; /* Change cursor on hover */
    display: flex; /* To center the icon inside */
    align-items: center;
    justify-content: center;
  }
  
  
  /* Adjustments to ensure the Drawer component aligns with the page's visual level */
  .deep-chat-drawer {
    .MuiDrawer-paper {
      position: relative; /* Ensures it doesn't float above other elements */
      z-index: 100; /* Adjust based on your site's specific z-index needs */
    }
  }
  
  /* borderRadius: '10px', position: 'fixed', bottom: '1%', right: '1%', zIndex: '1'  */