.link-preview {
  min-width: 100px;
  width: 300px;
  max-width: 350px;
  height: 300px;        /* limit the height of each block to 300px */
  margin: auto;
  display: block;
  color: inherit;
  text-decoration: none;
  margin: 1rem;
  border: 1px solid #d1d5db;
}

.link-preview-data {
  border-top: 1px solid #d1d5db;
  padding: 1rem;
}

.only-link {
  display: block;
  margin: 2rem;
  border: 1px solid #d1d5db;
  color: inherit;
  text-decoration: none;
  padding: 1rem;
}

.link-preview img {
  height: 160px;
  width: 100%;
  object-fit: contain;
}

.link-preview .title {
  font-weight: bold;
  font-size: 1.0rem;
  word-wrap: break-word;
}

.link-preview .desc {
  color: gray;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.link-preview .domain {
  color: gray;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
