.modal-33 {
    max-width: 33%;
    height: 225px;
}

.modal-content {
    height: 225px;
}

.modal-body p {
    word-wrap: break-word;
}
