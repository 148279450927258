body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.modal-33 {
    max-width: 33%;
    height: 225px;
}

.modal-content {
    height: 225px;
}

.modal-body p {
    word-wrap: break-word;
}

/* Position and style the chat toggle button */
.chat-icon {
    position: fixed;
    right: 10px; /* Adjust for a bit of margin from the edge */
    bottom: 10px; /* Same here for bottom margin */
    background-color: #007bff; /* Example color - blue */
    color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-radius: 50%; /* Make it perfectly circular */
    z-index: 1000; /* Ensure it's above most other elements */
    padding: 15px; /* Add some padding for the icon */
    cursor: pointer; /* Change cursor on hover */
    display: flex; /* To center the icon inside */
    align-items: center;
    justify-content: center;
  }
  
  
  /* Adjustments to ensure the Drawer component aligns with the page's visual level */
  .deep-chat-drawer {
    .MuiDrawer-paper {
      position: relative; /* Ensures it doesn't float above other elements */
      z-index: 100; /* Adjust based on your site's specific z-index needs */
    }
  }
  
  /* borderRadius: '10px', position: 'fixed', bottom: '1%', right: '1%', zIndex: '1'  */
.link-preview {
  min-width: 100px;
  width: 300px;
  max-width: 350px;
  height: 300px;        /* limit the height of each block to 300px */
  margin: auto;
  display: block;
  color: inherit;
  text-decoration: none;
  margin: 1rem;
  border: 1px solid #d1d5db;
}

.link-preview-data {
  border-top: 1px solid #d1d5db;
  padding: 1rem;
}

.only-link {
  display: block;
  margin: 2rem;
  border: 1px solid #d1d5db;
  color: inherit;
  text-decoration: none;
  padding: 1rem;
}

.link-preview img {
  height: 160px;
  width: 100%;
  object-fit: contain;
}

.link-preview .title {
  font-weight: bold;
  font-size: 1.0rem;
  word-wrap: break-word;
}

.link-preview .desc {
  color: gray;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.link-preview .domain {
  color: gray;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

